



















































































































import Vue from "vue";
import {
  GET_ONBOARDING_LOADING,
  GET_RECRUITER_DATA,
  ONBOARD_RECRUITER,
  RESET_RECRUITER_DATA,
  SET_RECRUITER_DATA
} from "@/store/modules/onboarding/constants";
import { mapActions, mapGetters, mapMutations } from "vuex";
import RecruiterCompanyName from "@/views/onboarding/recruiter/RecruiterCompanyName.vue";
import {
  RecruiterData,
  SetRecruiterDataPayload
} from "@/store/modules/onboarding/interfaces";
import RecruiterCompanyAvatar from "@/views/onboarding/recruiter/RecruiterCompanyAvatar.vue";
import RecruiterCompanySize from "@/views/onboarding/recruiter/RecruiterCompanySize.vue";
import RecruiterCompanyIndustry from "@/views/onboarding/recruiter/RecruiterCompanyIndustry.vue";
import RecruiterCompanyWebsite from "@/views/onboarding/recruiter/RecruiterCompanyWebsite.vue";
import RecruiterCompanyDescription from "@/views/onboarding/recruiter/RecruiterCompanyDescription.vue";
import RecruiterCompanyLocation from "@/views/onboarding/recruiter/RecruiterCompanyLocation.vue";
import { USER_STATE } from "@/store/modules/auth/constants";
import { UserState } from "@/store/modules/auth/interfaces";
import { ROOT_ERROR } from "@/store/modules/root/constants";

export default Vue.extend({
  name: "RecruiterIndex",
  components: {
    RecruiterCompanyLocation,
    RecruiterCompanyDescription,
    RecruiterCompanyWebsite,
    RecruiterCompanyIndustry,
    RecruiterCompanySize,
    RecruiterCompanyAvatar,
    RecruiterCompanyName
  },
  data() {
    return {
      recruiter_stepper: 1 as number,
      back_icon: require("@/assets/icons/linear/arrow-left-1.svg"),
      display_back_btn: true as boolean,
      disable_next_btn: false as boolean
    };
  },
  watch: {
    recruiter_stepper(new_value) {
      this.display_back_btn = new_value < 8;
    }
  },
  computed: {
    ...mapGetters("onboarding", {
      get_recruiter_data: GET_RECRUITER_DATA,
      get_onboarding_loading: GET_ONBOARDING_LOADING
    })
  },
  methods: {
    ...mapMutations("auth", {
      update_user_state: USER_STATE
    }),
    ...mapMutations("onboarding", {
      set_recruiter_data: SET_RECRUITER_DATA,
      reset_recruiter_data: RESET_RECRUITER_DATA
    }),
    ...mapMutations({
      set_root_error: ROOT_ERROR
    }),
    ...mapActions("onboarding", {
      onboard_recruiter: ONBOARD_RECRUITER
    }),
    async go_back_handle() {
      if (this.recruiter_stepper === 1) this.$router.back();
      else this.recruiter_stepper -= 1;
    },
    /**
     * Function to update stepper & update values in store
     * @param step: number => Current step number => required for updating value in store
     * @param value => value to update in store
     */
    async update_stepper({
      step,
      value
    }: {
      step: number;
      value: string | File;
    }) {
      // For company name
      if (step === 1) {
        const payload: SetRecruiterDataPayload = {
          obj_name: "company_name",
          value: value
        };
        this.set_recruiter_data(payload);
      }
      // For Company avatar
      else if (step === 2) {
        // Update store
        const payload: SetRecruiterDataPayload = {
          obj_name: "company_avatar",
          value: value
        };
        this.set_recruiter_data(payload);
      }
      // For company size
      else if (step === 3) {
        const payload: SetRecruiterDataPayload = {
          obj_name: "company_size",
          value: value
        };
        this.set_recruiter_data(payload);
      }
      // For company location
      else if (step === 4) {
        const payload: SetRecruiterDataPayload = {
          obj_name: "company_location",
          value: value
        };
        this.set_recruiter_data(payload);
      }
      // For company industry
      else if (step === 5) {
        const payload: SetRecruiterDataPayload = {
          obj_name: "company_industry",
          value: value
        };
        this.set_recruiter_data(payload);
      }
      // For company website
      else if (step === 6) {
        const payload: SetRecruiterDataPayload = {
          obj_name: "company_website",
          value: value
        };
        this.set_recruiter_data(payload);
      }
      // For company description
      else if (step === 7) {
        const payload: SetRecruiterDataPayload = {
          obj_name: "company_description",
          value: value
        };
        this.set_recruiter_data(payload);
        // Complete recruiter onboarding
        await this.complete_recruiter_onboarding();
      }
      this.recruiter_stepper += 1;
    },
    /**
     * Function to add recruiter data in backend
     */
    async complete_recruiter_onboarding() {
      const obj: RecruiterData = this.get_recruiter_data;
      // Validate recruiter data
      const valid_data = this.validate_data(obj);
      if (valid_data) {
        const payload: FormData = new FormData();
        payload.append("company_avatar", obj.company_avatar as never);
        payload.append("company_name", obj.company_name as never);
        payload.append("company_size", obj.company_size as never);
        payload.append("company_industry", obj.company_industry as never);
        payload.append("company_location", obj.company_location as never);
        payload.append("company_website", obj.company_website as never);
        payload.append("company_description", obj.company_description as never);
        // Call store function => for recruiter onboarding
        const response = await this.onboard_recruiter(payload);
        // If recruiter onboarding successfully => navigate to onboard success page
        if (response) {
          this.update_user_state(UserState.ONBOARDING_COMPLETED);
          await this.$router.push({
            name: "onboarding-success"
          });
          this.reset_recruiter_data();
        }
        // If recruiter onboarding failed => reset stepper
        else {
          this.recruiter_stepper = 0;
          this.reset_recruiter_data();
        }
      }
    },
    /**
     * Validate recruiter data
     * @param data{RecruiterData}
     * @return boolean
     */
    validate_data(data: RecruiterData): boolean {
      if (!data.company_name) {
        this.set_root_error("Company name is missing");
        this.recruiter_stepper = 1;
        return false;
      } else if (!data.company_avatar) {
        this.set_root_error("Company avatar is missing");
        this.recruiter_stepper = 2;
        return false;
      } else if (!data.company_size) {
        this.set_root_error("Company size is missing");
        this.recruiter_stepper = 3;
        return false;
      } else if (!data.company_location) {
        this.set_root_error("Company location is missing");
        this.recruiter_stepper = 4;
        return false;
      } else if (!data.company_industry) {
        this.set_root_error("Company industry is missing");
        this.recruiter_stepper = 5;
        return false;
      } else if (!data.company_description) {
        this.set_root_error("Company description is missing");
        this.recruiter_stepper = 7;
        return false;
      } else return true;
    }
  }
});
