




































import Vue from "vue";
import { mapGetters } from "vuex";
import { GET_ONBOARDING_LOADING } from "@/store/modules/onboarding/constants";
import { FormFieldRulesNumber } from "@/interfaces/v_form";

export default Vue.extend({
  name: "RecruiterCompanySize",
  data() {
    return {
      form: false as boolean,
      company_size_rules: [
        (value: number) => !!value || this.$t("rules.required").toString(),
        (value: number) =>
          value >= 0 ||
          this.$t("rules.onboarding.company-size-positive").toString()
      ] as FormFieldRulesNumber[],
      company_size: 0 as number
    };
  },
  computed: {
    ...mapGetters("onboarding", {
      get_onboarding_loading: GET_ONBOARDING_LOADING
    })
  },
  methods: {
    submit_form() {
      this.$emit("update_stepper", {
        step: 3,
        value: this.company_size.toString()
      });
    }
  }
});
