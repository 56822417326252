






































import Vue from "vue";
import { mapGetters } from "vuex";
import { GET_ONBOARDING_LOADING } from "@/store/modules/onboarding/constants";
import { FormFieldRulesString } from "@/interfaces/v_form";

export default Vue.extend({
  name: "RecruiterCompanyWebsite",
  data() {
    return {
      form: false as boolean,
      company_website_rules: [
        (value: string) => {
          if (value) {
            if (/^(?:https?:\/\/)?[A-Za-z0-9-]+\.[A-Za-z0-9./]+$/.test(value))
              return true;
            else return this.$t("recruiter-onboarding.invalid-url");
          } else return true;
        }
      ] as FormFieldRulesString[],
      company_website: null as string | null,
      web_icon: require("@/assets/icons/linear/keyboard-open.svg")
    };
  },
  computed: {
    ...mapGetters("onboarding", {
      get_onboarding_loading: GET_ONBOARDING_LOADING
    })
  },
  methods: {
    submit_form() {
      this.$emit("update_stepper", {
        step: 6,
        value: this.company_website ?? ""
      });
    }
  }
});
